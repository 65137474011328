import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import i18n from '../i18n';

import moment from 'moment';

import 'moment/locale/zh-cn';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ar';

import Icon from '../components/icon';

//create a new instance of the translation object so we can have a different
//locale here than on the other pages.
const I18nBoard = i18n.cloneInstance();

class Card extends Component{
    constructor(props) {
        super(props);
        this.state = {
            editing: null,
            content: this.props.content,
            transcontent: this.props.transcontent,
            lastupdated: this.props.lastupdated,
            lang: this.props.lang
        }

        this.startEditing = this.startEditing.bind(this);
        this.endEditing = this.endEditing.bind(this);
        this.showNew = this.showNew.bind(this);

    }
    showNew(){
        let lastupdated = this.state.lastupdated;
        let aDayAgo = moment().subtract(12, 'hours');
        let showNew = false;
        if (moment(lastupdated).isAfter(aDayAgo) && this.state.content.length > 0){
            showNew = true;
        }
        return showNew;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
          this.setState({ lang: this.props.lang }); 
          this.setLocale();
          //console.log("componentDidUpdate: " + this.state.lang);
        }
    }
    componentDidMount() {
        this.setLocale();
        //console.log("componentDidMount: " + this.state.lang);
    }
    startEditing( itemId ) {
        //only allow editing if readonlyid is not present in route
        if (!this.props.match.params.readonlyid) {
            this.setState({
                editing: itemId
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        //show updates to content when made in API call in parent component
        this.setState({ content: nextProps.content, lastupdated: nextProps.lastupdated, transcontent: nextProps.transcontent, lang: nextProps.lang });  
    }
    setLocale() {
        
        var lang = this.state.lang;

        if (lang !== undefined) {
            moment.locale(lang);
            var langFirstTwo = lang.slice(0, 2);
            I18nBoard.changeLanguage(langFirstTwo);
            //this is an anti-pattern, but seems to do the trick
            //to make the language switch when you change boards
            this.setState({});
        }

    }
    endEditing( itemId ) {
        //Update item in DB
        var lang = this.state.lang;

        let url = process.env.REACT_APP_API_URI + '/api/card/' + itemId + '/' + this.props.board + '/' + lang;

        let newContent = {content: document.getElementById(itemId).value.trim()};
        if (newContent.content !== this.state.content.trim()){
            fetch(url, {method: 'PUT',body: JSON.stringify(newContent),headers: {"Content-Type": "application/json"}})
                .then(response => response.json())
                .catch(e => console.log('error', e)); 

            this.setState({
                editing: null,
                content: document.getElementById(itemId).value,
                lastupdated: moment().format()
            });
        }else{
            this.setState({
                editing: null
            });
        }

    }
    render(){
        const updatedAgo = moment(this.state.lastupdated).fromNow();

        //from https://glaforge.appspot.com/article/sentiment-analysis-on-tweets
        var hsl = Math.floor((this.props.sentimentscore + 1) * 60) + ', ' + Math.min(Math.floor(this.props.sentimentmagnitude * 100), 100) + '%,80%';

        return(
            <div className="card relative mb-3 p-4 border w-full rounded overflow-hidden shadow-md">

                {this.props.title === 'Messages' &&
                    <Icon show={ this.showNew() } icon='new' />
                }

                <h2 ref="title" className="mb-3 pb-2 text-lg text-black">
                    {this.state.editing !== this.props.id &&
                        <span>{I18nBoard.t(this.props.title)}</span>
                    }
                    {this.state.editing === this.props.id &&
                        <span>{i18n.t(this.props.title)}</span>
                    }
                </h2>
                
                <div className="leading-tight text-black text-base bg-white">
                    
                    {this.state.editing === this.props.id &&
                        <div>
                            <textarea ref={input => input && input.focus()} rows="4" cols="50" className="w-full rounded-sm p-2" defaultValue={this.state.content} onBlur={ this.endEditing.bind( null, this.props.id ) } id={ this.props.id }  />

                            <span className="float-right" style={{ cursor: this.props.match.params.readonlyid ? 'default': 'pointer'}}><Icon icon='check' width='45' show='true' /></span>

                        </div>
                        
                    }
                    {this.state.editing !== this.props.id &&
                        <div>
                        
                        {this.state.transcontent === null &&
                            <p className="content" style={{ cursor: this.props.match.params.readonlyid ? 'default': 'pointer'}} onClick={ this.startEditing.bind( null, this.props.id ) }>{this.state.content}</p>
                        }

                        {this.state.transcontent !== null &&
                            <p className="content" style={{ cursor: this.props.match.params.readonlyid ? 'default': 'pointer'}} onClick={ this.startEditing.bind( null, this.props.id ) }>{this.state.transcontent}</p>
                        }

                        {this.state.content !== this.state.transcontent && this.state.transcontent !== null &&
                            <p className="text-grey py-2 trans-content text-sm" style={{ cursor: this.props.match.params.readonlyid ? 'default': 'pointer'}} onClick={ this.startEditing.bind( null, this.props.id ) }>
                            {i18n.t(this.props.title)}: {this.state.content}
                            </p>
                        }
                        
                        </div>
                    }
                    
                    <div className="updated mt-4 text-xs text-grey">
                    {I18nBoard.t('Updated')}: {updatedAgo} <span className="dot hidden" style={{ backgroundColor: "hsl("+hsl+")" }}></span>
                    </div>
                </div>
          </div> 
        )
    }
} 
//needed the withRouter HOC in order to get access to the URL params
export default withRouter(Card);