import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import I18n from '../i18n';

import { Trans } from 'react-i18next';

import Icon from '../components/icon';

import Nav from '../components/nav';

import Footer from '../components/footer';

import Slider from '../components/slider';

import dashboardImage from '../dashboard.png';
import ipadStandImage from '../ipadstand-piko-600w.png';
import boardDashImage from '../board-dash.gif';
import boardImage from '../board-readwrite.png';

import patient1 from '../img/patient1.png';
import patient2 from '../img/patient2.png';
import provider1 from '../img/provider1.png';
import provider2 from '../img/provider2.png';
import provider3 from '../img/provider3.png';
import provider4 from '../img/provider4.png';
import provider5 from '../img/provider5.png';


const images1 = [patient1,patient2];
const images2 = [provider1,provider2,provider3,provider4,provider5];

var defaultLocale = require('../util.js').defaultLocale;

class AboutOrg extends Component{
    constructor(props) {
        super(props);
        defaultLocale(I18n);
    }
    render(){
        return(
            <div className="about pt-4 border-t-4 border-blue font-sans">
                <Nav title="" />
                
                <div className="flex flex-wrap w-full content">

                    <div className="sm:w-2/3 w-full bg-blue-light text-white p-6">
                        <div className="pb-6 text-xl"><Trans>PIKO is simple, one-way, patient and family communication with care providers</Trans></div>
                        <div>
                        <ul className="list-reset boxy leading-normal ml-4">
                            <li><Trans>100% patient and family updated &mdash; no added work for providers</Trans></li>
                            <li><Trans>Provider dashboard for instant insights into individual patient well-being and overall organizational trends</Trans></li>
                            <li><Trans>Updated by family from anywhere in the world</Trans></li>
                            <li><Trans>No account or login for patients and families, the emailed link is their secure, sharable key for editing</Trans></li>
                        </ul>
                        </div>
                    </div>

                    <div className="sm:w-1/3 w-full bg-blue-light sm:text-left text-center">
                        <img className="sm:absolute hidden relative z-0 sm:mt-3 pl-4 pb-4" width="280" alt="Dashboard" src={boardImage} />
                    </div>

                    <div className="sm:w-3/4 w-full p-6 mb-2 z-40 how-works">
                        <h3 className="pb-6"><Trans>How it Works</Trans></h3>
                        
                        <div className="flex items-center pb-4">
                            <div className="float-left">
                                <Icon icon='create' width='65' show='true' />
                            </div>
                            <div className="w-full items-center pl-6 text-lg leading-normal">
                            <Trans>Log into your dashboard and <strong>invite patients via email</strong></Trans>
                            </div>
                        </div>

                        <div className="flex items-center pb-4">
                            <div className="float-left">
                                <Icon icon='edit' width='65' show='true' />
                            </div>
                            <div className="w-full items-center pl-6 text-lg leading-normal">
                            <Trans>We'll email a link so <strong>families can make edits anytime, from anywhere</strong></Trans>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="float-left">
                                <Icon icon='doc' width='65' show='true' />
                            </div>
                            <div className="w-full items-center pl-6 text-lg leading-normal">
                            <Trans>You see updates in real time on the dashboard allowing you to <strong>monitor and adjust care at the patient level and in aggregate</strong>.</Trans>
                            </div>
                        </div>

                    </div>

                    <div className="text-center w-full mb-8">
                        <a href="mailto:signup@pikoboard.com" className="shadow bg-blue hover:bg-blue-dark text-white py-3 px-4 text-lg">
                        Sign Up Now
                        </a>
                    </div>

                    <div className="sm:w-1/2 w-full bg-grey-lightest p-8 pb-16 text-center border-t-2">
                        <h4 className="pb-8"><Trans>What Patients and Families See</Trans></h4>
                       
                        
                        <Slider
                            options={{
                            autoPlay: 4000,
                            pauseAutoPlayOnHover: true,
                            wrapAround: false,
                            fullscreen: true,
                            adaptiveHeight: true,
                            prevNextButtons: false,
                            }}
                        >
                            {images1.map((image, index) => (
                            <div style={{ width: '90%', height: '400px', margin: '0 0.5em' }} className="carousel-cell" key={index}>
                                <img src={image} alt="" />
                            </div>
                            ))}
                        </Slider>
                        
                    </div>

                    <div className="sm:w-1/2 w-full bg-grey-lightest p-8 pb-16 text-center border-l-2 border-t-2">
                        <h4 className="pb-8"><Trans>What Providers See</Trans></h4>
                        
                        <Slider
                            options={{
                            autoPlay: 4000,
                            pauseAutoPlayOnHover: true,
                            wrapAround: false,
                            fullscreen: true,
                            adaptiveHeight: true,
                            prevNextButtons: false,
                            }}
                        >
                            {images2.map((image, index) => (
                            <div style={{ width: '90%', height: '400px', margin: '0 0.5em' }} key={index}>
                                <img src={image} alt="" />
                            </div>
                            ))}
                        </Slider>
                    </div>


                    <div className="w-full p-6 pb-8 bg-blue-light text-white">
                        <h3 className="pb-6"><Trans>Why do you need PIKO?</Trans></h3>
                    
                            <div className="pb-6 text-lg"><Trans>Demands are greater than ever to increase patient satisfaction and improve outcomes. PIKO can help by</Trans>:</div>
                                
                            <ul className="list-reset boxy leading-normal ml-4">
                                <li><Trans>Providing legible and structured information to inform patient and family discussions</Trans></li>
                                <li><Trans>Allowing you to identify issues (isolated or systemic) before they grow</Trans></li>
                                <li><Trans>Taking advantage of advanced artificial intelligence that constantly analyzes content and provides actionable real-time insights</Trans></li>
                                <li><Trans>Auto-detect translation from over 100 input languages, removing a barrier to providing well informed care</Trans></li>
                            </ul>

                    </div>


                    <div className="w-full p-6 mb-6 z-40 how-works">
                        <h3 className="pb-6"><Trans>Pricing</Trans></h3>
                        
                        <div className="flex flex-wrap">
                        <div className="md:w-1/3 w-full ml-auto p-4 md:mr-4 border">
                            <div className="text-center border-b pb-4 text-3xl">Up to 10 patients</div>
                            <div className="text-center pt-4 pb-2 text-4xl">$30</div>
                            <div className="text-center pb-2">per month flat</div>
                            <div className="text-center text-grey">Free 30 day trial</div>
                        </div>
                        <div className="md:w-1/3 w-full mr-auto p-4 md:ml-4 border mt-6 md:mt-0">
                            <div className="text-center border-b pb-4 text-3xl">11+ patients</div>
                            <div className="text-center pt-4 pb-2 text-4xl">$3</div>
                            <div className="text-center pb-2">per patient/month</div>
                        </div>
                        </div>
                    </div>

                    <div className="text-center w-full">
                        <a href="mailto:signup@pikoboard.com" className="shadow bg-blue hover:bg-blue-dark text-white py-3 px-4 mb-6 text-lg">
                        Start Your Free Trial
                        </a>
                    </div>

                    <div className="w-full p-6 pb-8">
                    
                        <div className="text-sm italic hidden">Note: The term "family" is used above to represent anyone other than the care provider that is providing support for the patient. </div>
                    
                    </div>
                    
                </div>
            <Footer />
            </div>
        )
    }
} 

export default AboutOrg;