import React, { Component } from 'react';

let empty = false;

class Icon extends Component{
    render(){
        if (this.props.show && this.props.icon === 'new') {
            return (
            <span className="float-right text-grey-dark mb-2 inline-flex items-center rounded-full border border-grey-light bg-grey-lightest text-xs pl-1 pt-1 pb-1 pr-2 leading-none"><span className="inline-flex rounded-full bg-green-light text-white mr-1"><svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="fill-current h-4 w-4"><path d="M5.8 9.4c-.33-.442-.958-.53-1.4-.2-.442.33-.53.958-.2 1.4l3 4c.38.508 1.134.537 1.553.06l7-8c.363-.417.32-1.05-.094-1.413-.417-.363-1.05-.32-1.413.094L8.06 12.414 5.8 9.4z"></path></svg></span> <span>New</span></span>
            )
        } else {
            empty = true;
        }
        
        if (this.props.show && this.props.icon === 'create') {
            return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="910 550 100 125" enableBackground="new 910 550 100 100" width={this.props.width}><g><polygon points="991.501,632.236 959.565,632.236 941.398,632.236 927.029,632.236 927.029,617.878 924.099,614.948    924.099,635.166 994.441,635.166 994.441,597.36 991.501,600.29  "/><polygon points="927.029,567.764 982.423,567.764 985.353,564.834 924.099,564.834 924.099,573.333 927.029,576.263  "/><polygon points="1000,573.333 997.661,570.983 950.476,618.469 922.339,590.042 920,592.381 950.476,622.567  "/></g></svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'tablet') {
            return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="910 550 100 125" enableBackground="new 910 550 100 100" width={this.props.width}><g><path d="M929.228,560v80h61.543v-80H929.228z M987.696,563.076v6.152h-55.392v-6.152H987.696z M987.696,572.304v49.228h-55.392   v-49.228H987.696z M932.304,636.924V624.62h55.392v12.304H932.304z"/><circle cx="960" cy="630.772" r="2.457"/></g></svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'edit') {
            return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="910 550 100 125" enableBackground="new 910 550 100 100" width={this.props.width}><g><polygon points="993.604,636.789 923.211,636.789 923.211,566.396 964.797,566.396 967.995,563.198 920.014,563.198 920.014,640    996.803,640 996.803,591.992 993.604,595.19  "/><path d="M936.003,623.997h12.792l51.192-51.192L987.195,560l-51.192,51.192V623.997z M995.513,572.805l-7.673,7.672l-8.316-8.317   l7.672-7.672L995.513,572.805z M939.201,612.469l38.079-37.743l8.317,8.317L947.518,620.8h-8.317V612.469z"/></g></svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'doc') {
            return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 640" enableBackground="new 0 0 512 512" width={this.props.width}><path d="M330,248.6c29.8-34.8,48.4-85.4,48.4-123.7C378.4,57.1,323.3,2,255.5,2S132.6,57.1,132.6,124.9c0,38.3,18.6,88.9,48.4,123.7  C0.7,309.2,19.6,476,27.5,510l456,0C510.4,393.5,439.2,280.5,330,248.6z M255.5,18.4c43.1,0,80.2,25.7,96.9,62.6  c-40.9,27.9-82.4-3.6-82.4-3.6c-29-16.9-65.4-15.3-92.8,3.9l-24.8,17.4C164.1,52.7,205.8,18.4,255.5,18.4z M149.2,121l37.4-26.3  c22.2-15.6,51.7-16.8,75.1-3.2c0,0,45.7,35,96.4,5.3c2.4,8.9,3.9,18.3,3.9,28c0,58.2-47.7,147.5-106.5,147.5S149,183.1,149,124.9  C149,123.6,149.1,122.3,149.2,121z M469.9,493.6l-428.7,0c-12.4-76.9,19.1-150.8,77.1-195.3c3.5,23,10.3,45.1,20.4,66.1  c-25.3,15.9-37.2,47.8-26.9,77.1l13.6,38.6l30.9-10.9l-5.4-15.5l-15.5,5.4l-8.2-23.2c-9-25.6,4.5-53.7,30.1-62.7  c25.5-9,53.7,4.5,62.7,30.1l8.1,23.2l-15.5,5.4l5.4,15.5l30.9-10.9l-13.6-38.7c-11.8-33.6-48.3-51.5-81.9-40.6  c-10.6-22-17.4-45.4-20.1-69.7c18.2-11.5,38.6-20.3,60.7-25.8c17.9,16.3,38.7,26.7,61.4,26.7c22.7,0,43.5-10.4,61.4-26.7  c25.8,6.4,49.3,17.3,69.7,31.9c-4.8,21.8-12.8,42.5-24,61.7c-2.8-0.8-5.7-1.4-8.8-1.4c-18.1,0-32.8,14.7-32.8,32.8  s14.7,32.8,32.8,32.8c18.1,0,32.8-14.7,32.8-32.8c0-9.1-3.7-17.3-9.8-23.3c10.7-18.5,18.7-38.1,24-58.8  C453.6,350.1,481.6,420.6,469.9,493.6z M370.2,387.1c0,9-7.4,16.4-16.4,16.4c-9,0-16.4-7.3-16.4-16.4s7.4-16.4,16.4-16.4  C362.9,370.7,370.2,378.1,370.2,387.1z"/></svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'trash') {
            return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" x="0px" y="0px" width={this.props.width}><g data-name="Layer 1"><path d="M72.84,23.54H59.55V22a4.66,4.66,0,0,0-4.65-4.67H45.1A4.66,4.66,0,0,0,40.45,22v1.5H27.16a1.5,1.5,0,0,0,0,3h2l4.36,52.92A1.5,1.5,0,0,0,35,80.84H65a1.5,1.5,0,0,0,1.5-1.38l4.36-52.92h2a1.5,1.5,0,0,0,0-3ZM43.45,22a1.66,1.66,0,0,1,1.65-1.67h9.8A1.66,1.66,0,0,1,56.55,22v1.5H43.45Zm20.13,55.8H36.42l-4.23-51.3H67.82Z"/><path d="M50,32.56a1.5,1.5,0,0,0-1.5,1.5V70.31a1.5,1.5,0,0,0,3,0V34.06A1.5,1.5,0,0,0,50,32.56Z"/><path d="M42,32.56a1.5,1.5,0,0,0-1.5,1.5V70.31a1.5,1.5,0,0,0,3,0V34.06A1.5,1.5,0,0,0,42,32.56Z"/><path d="M56.55,34.06V70.31a1.5,1.5,0,0,0,3,0V34.06a1.5,1.5,0,0,0-3,0Z"/><path d="M50,.24A49.76,49.76,0,1,0,99.76,50,49.82,49.82,0,0,0,50,.24Zm0,96.52A46.76,46.76,0,1,1,96.76,50,46.81,46.81,0,0,1,50,96.76Z"/></g></svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'check') {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enableBackground="new 0 0 100 100" width={this.props.width}><path d="M71,49.3c0,0.2,0,0.4,0,0.7c0,11.6-9.4,21-21,21s-21-9.4-21-21c0-11.6,9.4-21,21-21c5.2,0,10,1.9,13.6,5.1l2.8-2.8  C62.1,27.3,56.3,25,50,25c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25c0-1.5-0.1-2.9-0.4-4.3L71,49.3z"/><polygon points="39.1,43.9 39.1,43.9 34.9,48.1 34.9,48.1 50,63.2 82.1,31.1 80,29 77.9,26.9 77.9,26.9 50,54.8 "/></svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'delete') {
            return (
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={this.props.width} className={this.props.classes} viewBox="0 0 16 16">
                <path fill="#000000" d="M2 5v10c0 0.55 0.45 1 1 1h9c0.55 0 1-0.45 1-1v-10h-11zM5 14h-1v-7h1v7zM7 14h-1v-7h1v7zM9 14h-1v-7h1v7zM11 14h-1v-7h1v7z"></path>
                <path fill="#000000" d="M13.25 2h-3.25v-1.25c0-0.412-0.338-0.75-0.75-0.75h-3.5c-0.412 0-0.75 0.338-0.75 0.75v1.25h-3.25c-0.413 0-0.75 0.337-0.75 0.75v1.25h13v-1.25c0-0.413-0.338-0.75-0.75-0.75zM9 2h-3v-0.987h3v0.987z"></path>
                </svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'eye') {
            return (
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={this.props.width} className={this.props.classes} viewBox="0 0 16 16">
                <path fill="#000000" d="M8 3c-3.489 0-6.514 2.032-8 5 1.486 2.968 4.511 5 8 5s6.514-2.032 8-5c-1.486-2.968-4.511-5-8-5zM11.945 5.652c0.94 0.6 1.737 1.403 2.335 2.348-0.598 0.946-1.395 1.749-2.335 2.348-1.181 0.753-2.545 1.152-3.944 1.152s-2.763-0.398-3.945-1.152c-0.94-0.6-1.737-1.403-2.335-2.348 0.598-0.946 1.395-1.749 2.335-2.348 0.061-0.039 0.123-0.077 0.185-0.114-0.156 0.427-0.241 0.888-0.241 1.369 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.481-0.085-0.942-0.241-1.369 0.062 0.037 0.124 0.075 0.185 0.114v0zM8 6.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5 0.672-1.5 1.5-1.5 1.5 0.672 1.5 1.5z"></path>
                </svg>
            )
        } else {
            empty = true;
        }

        if (this.props.show && this.props.icon === 'envelope') {
            return (
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={this.props.width} className={this.props.classes} viewBox="0 0 16 16">
                <path fill="#000000" d="M14.5 2h-13c-0.825 0-1.5 0.675-1.5 1.5v10c0 0.825 0.675 1.5 1.5 1.5h13c0.825 0 1.5-0.675 1.5-1.5v-10c0-0.825-0.675-1.5-1.5-1.5zM6.23 8.6l-4.23 3.295v-7.838l4.23 4.543zM2.756 4h10.488l-5.244 3.938-5.244-3.938zM6.395 8.777l1.605 1.723 1.605-1.723 3.29 4.223h-9.79l3.29-4.223zM9.77 8.6l4.23-4.543v7.838l-4.23-3.295z"></path>
                </svg>
            )
        } else {
            empty = true;
        }




        

        if (empty === true) {
            return null;
        }
       
    }
} 

export default Icon;
