import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import auth0Client from '../auth';

import I18n from '../i18n';

import Logo from '../logo.png'

class Nav extends Component{
    constructor(props) {
        super(props);
        this.state = {
            boards: [],
            isToggleOn: false,
            orgUrl: ''
        }

        this.removeBoard = this.removeBoard.bind(this);
        this.loadBoards = this.loadBoards.bind(this);
        this.toggleBoardPopover = this.toggleBoardPopover.bind(this);
        this.hideBoardPopover = this.hideBoardPopover.bind(this);
    }
    toggleBoardPopover(){
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }));
    }
    hideBoardPopover(){
        this.setState({isToggleOn: false});
    }
    componentDidMount() {
        this.loadBoards();
    }
    componentWillReceiveProps() {
        this.loadBoards();
    }
    loadBoards() {
        //pull in existing boards from local storage
        let boards = JSON.parse(localStorage.getItem('myDashboards')) || [];
        this.setState({boards: boards});
    }
    removeBoard(boardId) {
        var r = window.confirm("Are you sure that you would like to remove this board from your list?");
        if (r === true) {
            let oldBoards = this.state.boards;
            let newBoards = oldBoards.filter(function(item){ return item[0] !== boardId });
            this.setState({boards: newBoards});

            localStorage.setItem('myDashboards', JSON.stringify(newBoards));
        }
    }
    signOut() {
        auth0Client.signOut();
        
    };
    render(){
        return(
            <div className="flex flex-wrap">
                <div className="w-full mr-4 ml-4 mb-3 sm:mb-0">
                    <div className="float-right">
                    
                        
                        {auth0Client.isAuthenticated() &&
                            <div className="text-right w-full mb-2">
                            {auth0Client.getProfile().name}
                            </div>
                        }
                        
                        {auth0Client.isAuthenticated() &&
                            <span>
                            <button className="bg-grey hover:bg-grey-dark text-white text-sm py-2 px-4 rounded-full" onClick={this.signOut}>Sign Out</button>
                            <Link to={'/org/' +  auth0Client.getProfile()["http://pikoboard.com/org"]} className="shadow bg-blue hover:bg-blue-dark text-white py-2 px-4 rounded-full ml-2">{I18n.t('Dashboard')}</Link>
                            </span>
                        }

                        {!auth0Client.isAuthenticated() &&
                            <button className="shadow bg-blue hover:bg-blue-dark text-white py-2 px-4 rounded-full ml-2" onClick={auth0Client.signIn}>{I18n.t('Sign In')}</button>
                        }

                        {this.state.boards.length > 0 &&
                        <button className="shadow bg-blue hover:bg-blue-dark text-white py-2 px-4 rounded-full ml-2" onClick={this.toggleBoardPopover}>{I18n.t('My Boards')}</button>
                        }
                        
                        {this.state.isToggleOn &&
                            <div className="board-box m-4 p-4 bg-grey-light z-50 shadow-md" onClick={this.hideBoardPopover}>
                                <ul className="list-reset text-base text-grey-darker leading-loose boards">
                                {this.state.boards.map(function(board, index){
                                    return <li key={index}><Link className="board-link" to={`/board/${board[0]}/${board[2]}`}>{board[1]}</Link> | <a style={{cursor: 'pointer'}} onClick={this.removeBoard.bind(null, board[0])}>{I18n.t('remove')}</a></li>
                                }.bind(this))}
                                </ul>
                                {this.state.boards.length === 0 &&
                                    <p className=" text-sm text-grey-darker">{I18n.t('You have no boards yet. Click the button below to create a board.')}</p>
                                }
                            <Link to="/" className="bg-blue hover:bg-blue-dark text-white text-sm py-2 px-4 mt-6 rounded-full hidden float-left">{I18n.t('New Board')}</Link>
                            </div>
                        }



                    </div>
                </div>
                <div className="w-full pt-4 flex flex-wrap">
                    <div className="md:w-1/6 w-full pl-6">
                        <Link to="/"><h1 ref="title" className="md:pb-4 pb-2 app-title text-3xl font-sans float-left"><img alt="PIKO Logo" src={Logo} style={{verticalAlign: 'top'}} /> PIKO</h1></Link>
                    </div>
                    <div className="md:w-5/6 w-full md:pr-4 pl-6 self-end">
                        <div className="md:float-right float-left pb-2 text-xl font-sans">
                        {this.props.title} 
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
} 

export default Nav;