import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component{
    constructor(props) {
        super(props);
    }
    render(){
        return(
            <div className="text-center pb-4 pt-6 text-sm text-grey">
                <Link to="/terms" className="text-sm text-grey">Terms of Service</Link> | <Link to="/privacy" className="text-sm text-grey">Privacy Policy</Link> | <Link to="/" className="text-sm text-grey">Home</Link>
                <br />
                <div className="text-xs pt-2">&copy; pikoboard.com</div>
            </div>
        )
    }
} 

export default Footer;