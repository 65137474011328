import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './auth';

class Login extends Component {
  componentDidMount() {
    auth0Client.signIn();
  }

  render() {
    return (
      <p className="hidden">Redirecting to login....</p>
    );
  }
}

export default withRouter(Login);