import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './auth';

class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = {redirectToReferrer: false}

}
  async componentDidMount() {
    await auth0Client.handleAuthentication();
    this.props.history.replace('/org/' +  auth0Client.getProfile()["http://pikoboard.com/org"]);
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default withRouter(Callback);