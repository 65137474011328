import React from 'react';

const Loader = () => (
    <div className="loader">
        <p className="loading">Loading</p>
        <span className="bar bar-1"></span>
        <span className="bar bar-2"></span>
        <span className="bar bar-3"></span>
        <span className="bar bar-4"></span>
        <span className="bar bar-5"></span>
        <span className="bar bar-6"></span>
    </div>
);

export default Loader;