import React from 'react';

import Card from '../components/card';

const CardList = ({cards,lang}) => {
    // Map through the cards
    const cardNode = cards.map((card) => {
      return (
      <Card 
        key={card.id} 
        id={card.id} 
        board={card.board}
        lang={lang}
        title={card.title} 
        color={card.color} 
        rank={card.rank} 
        content={card.content} 
        transcontent={card.transcontent}
        lastupdated={card.lastupdated}
        sentimentscore={card.sentimentscore}
        sentimentmagnitude={card.sentimentmagnitude} />
        )
    });
    return (cardNode);
  }

  export default CardList;