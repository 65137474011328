import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import i18n from '../i18n';

import socketIOClient from 'socket.io-client';
import { Offline } from 'react-detect-offline';

import CardList from '../components/cardlist';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Loader from '../components/loader';
import Banner from '../components/banner';

var defaultLocale = require('../util.js').defaultLocale;

class Dashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            dataOne: [],
            dataTwo: [],
            board: [],
            isFetching: true,
            isActivated: false,
            isNotFound: false,
            response: false,
            id:props.match.params.id || props.match.params.readonlyid,
            email:props.match.params.email,
            key:props.match.params.key,
            readonlyid:props.match.params.readonlyid,
            endpoint: process.env.REACT_APP_API_URI
        }
        this.storeBoard = this.storeBoard.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this._initializeComponent = this._initializeComponent.bind(this);

        defaultLocale(i18n);
    }
    _initializeComponent() {
        let self = this;
        let url;

        //determine if we have the read only key or read/write key
        if (this.state.readonlyid) {
            url = process.env.REACT_APP_API_URI + '/api/board/readonly/' + this.state.id;
        }else{
            url = process.env.REACT_APP_API_URI + '/api/board/' + this.state.id + '/' + this.state.email + '/' + this.state.key;
        }
    
         fetch(url, {method: 'GET'})
         .then(
           function(response) {
            if (response.status !== 200) {
               console.log('Looks like there was a problem. Status Code: ' +
                 response.status);
               return;
             }
             response.json().then(function(board) {
               if (board.length > 0) {
                self.setState({board: board[0], isActivated: board[0].activated, lang: board[0].lang});
                //store the board in local storage if it's new and it's not read-only
                if (!self.state.readonlyid) {
                    self.storeBoard();
                }
               }else{
                self.setState({isNotFound: true, isActivated: true});
                return;
               }
             });
           }
         )
         .catch(function(err) {
           console.log('Fetch Error :-S', err);
         });

        //if the key exists, let's just assume it's valid and hide the 
        //banner. We'll delete the board in 24 hrs anyway so no harm if they
        //can figure out how to pass an invalid key
        if (this.state.key) {
            self.setState({isActivated: true});
        }

        this.fetchData();
 
        const { endpoint } = this.state;
        const socket = socketIOClient(endpoint);

        // let's assume that the client page, once rendered, knows what board it wants to join
        let board = this.state.id;

        socket.on('connect', function() {
            // Connected, let's sign-up for to receive messages for this board
            socket.emit('board', board);
            console.log('Subscribed to ' + board);
        });

        socket.on('time', function(data) {
            console.log('Data update elseware, update here:', data);
            self.fetchData();
        });
    }
    fetchData() {
        let self = this;
        let url;

        //determine if we have the read only key or read/write key
        if (this.state.readonlyid) {
            url = process.env.REACT_APP_API_URI + '/api/cards/readonly/' + this.state.id;
        }else{
            url = process.env.REACT_APP_API_URI + '/api/cards/' + this.state.id;
        }

        fetch(url, {method: 'GET'})
        .then(
          function(response) {
            if (!response.ok) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              return;
            }

            response.json().then(function(cards) {
    
              self.setState({ dataOne: cards.filter((card) => card.column === 1 ) });
              self.setState({ dataTwo: cards.filter((card) => card.column === 2 ) });

              self.setState({isFetching:false});

            });
          }
        )
        .catch(function(err) {
          console.log('Fetch Error :-S', err);
        });
    }
    componentDidMount() {
        this._initializeComponent();
    }
    componentWillReceiveProps(nextProps){
        //window.location.reload();
        //seems like these bottom two lines should do the trick without a reload but seems that's not the case, so hacked in a simple page reload to get the new data.
        //However, this line below is needed in order to update the list of boards in the nav so let's leave it.
        this.setState({id:nextProps.match.params.id, email:nextProps.match.params.email, key:nextProps.match.params.key});
        //this._initializeComponent();
     }
    removeFromOrg(board) {
        console.log(board);
    }
    storeBoard(){
        //designed to save the board to localstorage the first time they visit on this device
        let self = this;
        //pull in existing boards from local storage
        let myDashboards = JSON.parse(localStorage.getItem('myDashboards')) || [];
        let result = [];

        //check to see if current dashboard is already stored
        if (myDashboards) {
            result = myDashboards.filter(function(v,i) {
                return v[0] === self.props.match.params.id;
            });
        }

        //it's not stored, so let's store it
        if (result.length === 0) {
            var thisBoard = [];
            thisBoard[0] = this.state.board.id;
            thisBoard[1] = this.state.board.name;
            thisBoard[2] = this.state.board.email;

            myDashboards.unshift(thisBoard);

            localStorage.setItem('myDashboards', JSON.stringify(myDashboards));
        }

    }
    render(){
        return(
            <div>
                {!this.state.isActivated && !this.state.isFetching &&
                    <Banner />
                }
                <div className="dashboard pt-4 border-t-4 border-blue font-sans">
                    
                    <Nav title={this.state.board.name} lang={this.state.lang} />

                    {this.state.isFetching &&
                        <div className="flex justify-center items-center h-screen font-bold">
                            <div className="text-center pb-4 py-2 m-2">
                            <Loader />
                            </div>
                        </div>
                    }
                    {this.state.isNotFound &&
                        <div className="flex justify-center items-center h-screen font-bold">
                            <div className="text-center pb-4 m-2">
                            {i18n.t('Board not found at this address.')}
                            <br />
                            <br />
                            <strong><Link className="blue" to="/">Create a board</Link></strong> 
                            </div>
                        </div>
                    }
                    {!this.state.isFetching && !this.state.isNotFound &&
                        <div>
                            <Offline>
                            <div className="ml-4 mr-4 mb-4 bg-red-lightest border-2 border-red-light text-red-dark px-4 py-4 rounded shadow-md" role="alert">
                                <strong className="font-bold">Offline: </strong>
                                <span className="block sm:inline">The data below may be out of date. Please check the internet connection on this device and reload the page when it is restored.</span>
                            </div>
                            </Offline>

                            <div className="flex flex-wrap">
                                <div className="w-full md:w-2/3 pl-4 pr-4">
                                    <CardList cards={this.state.dataOne} lang={this.state.lang} />
                                </div>
                                <div className="w-full pr-4 pl-4 md:w-1/3 md:pl-0">
                                    <CardList cards={this.state.dataTwo} lang={this.state.lang} />
                                </div>
                                <div className="w-full pl-4 pr-4">
                                    <p className="text-grey py-2 trans-content text-sm">
                                    {i18n.t('Auto translated to')} {this.state.lang}<br/>
                                    {this.state.board.orgCode &&
                                        <span>
                                        {i18n.t('Member of ')} {this.state.board.orgCode} {i18n.t('org')} | <a className="underline" style={{cursor: 'pointer'}} onClick={this.removeFromOrg.bind(null, this.state.board)}>{i18n.t('remove')}</a>
                                        </span>
                                    }
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            <Footer />
            </div>
        )
    }
} 

export default Dashboard;