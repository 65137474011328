import React from 'react';

import { Trans } from 'react-i18next';

const Banner = () => (
    <div className="bg-orange-lightest text-orange-darkest px-6 py-4 font-sans" role="alert">
    <div className="flex">
    <div className="py-1"><svg className="fill-current h-6 w-6 text-orange mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <div>
        <p className="font-bold mb-2"><Trans>This board is unverified</Trans></p>
        <p className="text-sm"><Trans>To remove this message and keep this board active please click on the link we sent you via e-mail; otherwise this board will self destruct in 24 hours from creation.</Trans></p>
    </div>
    </div>
    </div>
);

export default Banner;