import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, withRouter, Redirect } from 'react-router-dom';

import auth0Client from './auth';

import Callback from './callback';
import Login from './login';
import Terms from './components/terms';
import Privacy from './components/privacy';
import Dashboard from './components/dashboard';
import AboutOrg from './components/aboutorg';
import CreateBoard from './components/createboard';
//import Welcome from './components/welcome';
import Vieworg from './components/vieworg';

import './tailwind.min.css';
import './custom.css';
//import registerServiceWorker from './registerServiceWorker'; //commenting out PWA for now

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth0Client.isAuthenticated() === true
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
  )} />
)

class App extends Component {
  constructor(props) {
    super(props);
    
  }
  async componentDidMount() {
    //if (this.props.location.pathname === '/callback') return;
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }
  }

  render(){
    return(
      <Router>
        <div>
          <Route exact path="/" component={AboutOrg} />
          <Route path="/board/:id/:email/:key?" component={Dashboard} />
          <Route path="/boardr/:readonlyid" component={Dashboard} />
          <PrivateRoute path='/org/:orgid' component={Vieworg} />
          <Route path='/board/create' component={CreateBoard} />
          <Route exact path='/callback' component={Callback}/>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/terms' component={Terms}/>
          <Route exact path='/privacy' component={Privacy}/>
        </div>
      </Router>
    )
  }
}

export default withRouter(App);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
//registerServiceWorker(); commenting out PWA for now