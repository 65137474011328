import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import queryString from 'query-string';

import I18n from '../i18n';

import Loader from '../components/loader';

class CreateBoard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            boardEmail: '',
            boardName: '', 
            orgCode: '',
            orgId: '',
            boardType: 'elder',
            boardLang: 'en', 
            redirect: false, 
            processing: false,
            confirmNotProvider: false,
            boardId: ''
        };

        this.createBoard = this.createBoard.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        //const qsValues = queryString.parse(this.props.location.search);
        //this.setState({orgCode: qsValues.orgcode});
        this.setState({orgId: this.props.orgId});
        
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
    createBoard(event) {
        event.preventDefault();
        let self = this;

        if (this.state.boardEmail !== '' && this.state.boardName !== ''){
            let boardData = {"email":this.state.boardEmail,"name":this.state.boardName,"orgId":this.state.orgId,"type":this.state.boardType,"lang":this.state.boardLang};
            self.setState({processing: true});

            fetch(process.env.REACT_APP_API_URI + '/api/board/create', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(boardData)
            })
            .then(
                function(response) {
                  if (!response.ok) {
                    console.log('Looks like there was a problem. Status Code: ' +
                      response.status);
                      self.setState({redirect: false, processing: false});
                    return;
                  }
      
                  response.json().then(function(boardId) {
                    
                    self.setState({boardId:boardId, redirect: true, processing: false});
                    self.props.closeTheModal();
      
                  });
                }
              )
              .catch(function(err) {
                console.log('Fetch Error :-S', err);
              });
        }else{
            alert('Both fields are required.');
        }

    }
    render(){
        if (this.state.redirect) {
            //return <Redirect to={'/board/' + this.state.boardId + '/' + this.state.boardEmail} />;
        }
        if (this.state.processing) {
            return (
                <div className="flex flex-wrap w-full pl-2">
                    <Loader />
                </div>
            )
        }
        return(
            <div>
                <form onSubmit={this.createBoard}>
                    <div className="mb-6">
                        <label className="block text-grey-dark font-bold mb-1 pr-4 float-left">
                            {I18n.t('Board Name')}
                        </label>
                        <input required maxLength="100" className="border-2 border-grey-lighter hover:border-blue rounded w-full py-3 px-4 text-grey-darker" placeholder={I18n.t('e.g. Room 205 - Brown')} id="board-name" type="text" name="boardName" value={this.state.boardName} onChange={this.handleInputChange} />
                    </div>
                    <div className="mb-6">
                        <label className="block text-grey-dark font-bold mb-1 pr-4 float-left">
                            {I18n.t('Primary Editor Email')}
                        </label>
                        <input required maxLength="100" className="border-2 border-grey-lighter hover:border-blue rounded w-full py-3 px-4 text-grey-darker" id="board-email" type="email" name="boardEmail"  value={this.state.boardEmail} onChange={this.handleInputChange} />
                    </div>
                    <div className="mb-6">
                        <label className="block text-grey-dark font-bold mb-1 pr-4 float-left">
                            {I18n.t('Board Type')}
                        </label>
                        <select name="boardType" className="block appearance-none w-full border-2 border-grey-lighter hover:border-blue bg-white rounded py-3 px-4 pr-8" value={this.state.boardType} onChange={this.handleInputChange}>
                            <option value="elder">{I18n.t('Elder or Hospice Care')}</option>
                            <option value="peds">{I18n.t('Pediatric')}</option>
                            <option value="adult">{I18n.t('Adult')}</option>
                            <option value="outpatient">{I18n.t('Outpatient Visit')}</option>
                        </select>
                    </div>
                    <div className="mb-6">
                        <label className="block text-grey-dark font-bold mb-1 pr-4 float-left">
                            {I18n.t("Provider's Language")}
                        </label>
                        <select name="boardLang" className="block appearance-none w-full border-2 border-grey-lighter hover:border-blue bg-white rounded py-3 px-4 pr-8" value={this.state.boardLang} onChange={this.handleInputChange}>
                            <option value="en">{I18n.t('English')}</option>
                            <option value="es">{I18n.t('Spanish')}</option>
                            <option value="fr">{I18n.t('French')}</option>
                            <option value="de">{I18n.t('German')}</option>
                            <option value="zh-CN">{I18n.t('Chinese')}</option>
                            <option value="ar">{I18n.t('Arabic')}</option>
                        </select>
                    </div>
                    <div className="mb-6 hidden">
                        <label className="block text-grey-dark font-bold mb-1 pr-4 float-left">
                            {I18n.t('Organization Code')}
                        </label>
                        <input maxLength="100" className="border-2 border-grey-lighter hover:border-blue rounded w-full py-3 px-4 text-grey-darker" id="org-code" type="text" name="orgCode" value={this.state.orgCode || ''} onChange={this.handleInputChange} />
                    </div>

                    <div className="mb-8 flex">
                        <div>
                            <input id="confirmNotProvider" name="confirmNotProvider" className="mr-2 leading-tight" type="checkbox" onChange={this.handleInputChange} />
                        </div>
                        <div className="float-left">
                        <label htmlFor="confirmNotProvider" className="text-grey-dark float-left text-left">
                            {I18n.t('I confirm that the Primary Editor Email provided is not owned by or accessibled to a HIPPA Covered Entity.')}
                        </label>
                        </div>
                    </div>
                    <div>
                        <input disabled={!this.state.confirmNotProvider} className={"shadow bg-blue hover:bg-blue-dark text-white py-2 px-4 rounded-full " + (!this.state.confirmNotProvider ? 'opacity-50 cursor-not-allowed':'')} type="submit" value={I18n.t("Create Board")} />
                    </div>
                </form>
            </div>
        )
    }
} 
//needed the withRouter HOC in order to get access to the URL params
export default withRouter(CreateBoard);