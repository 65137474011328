import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import cogoToast from 'cogo-toast';

import { ResponsiveContainer, ScatterChart, Scatter, LineChart, Line, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';

import i18n from '../i18n';

import Icon from '../components/icon';

import Footer from '../components/footer';

import moment from 'moment';

import ReactModal from 'react-modal';

import ReactWordcloud from "react-wordcloud";

import 'balloon-css';

import 'moment/locale/zh-cn';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ar';

import Nav from '../components/nav';
import Loader from '../components/loader';
import CreateBoard from '../components/createboard';

var defaultLocale = require('../util.js').defaultLocale;
ReactModal.setAppElement('#root');


const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
        <div className="custom-tooltip border p-2 bg-white">
            <p className="font-bold label">{payload[0].payload.name}</p>
        </div>
        );
        
    }
    return null;
};

class Vieworg extends Component{
    constructor(props) {
        super(props);
        this.state = {
            boards: [],
            stats: [],
            goals: [],
            needs: [],
            org: [],
            maxBoards: 0,
            needsDisplay: [],
            isFetching: true,
            response: false,
            showModal: false,
            boardFilter: "",
            orgId:props.match.params.orgid
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.loadBoards = this.loadBoards.bind(this);
        this.removeFromOrg = this.removeFromOrg.bind(this);
        this._initializeComponent = this._initializeComponent.bind(this);

        defaultLocale(i18n);

    }
    _initializeComponent() {

        moment.locale('en');

        this.loadBoards();

        //load stats
        let url = process.env.REACT_APP_API_URI + '/api/stats/v2/' + this.state.orgId;

        fetch(url, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            var needs = [];
            var goals = [];
            data[1].forEach(function(doc) {
                if (doc.stattype === 'goals'){
                    goals.push({text:doc.statvaluetext,value:doc.frequency});
                }
                if (doc.stattype === 'needs'){
                    needs.push({text:doc.statvaluetext,value:doc.frequency});
                }
            })
           this.setState({stats: data, goals: goals, needs: needs })
           this.setState({isFetching:false});
        })
       .catch(err => console.error(url, err.toString()))


       //load org details
       url = process.env.REACT_APP_API_URI + '/api/org/details/' + this.state.orgId;

       fetch(url, {method: 'GET'})
       .then(response => response.json())
       .then(data => {
            this.setState({org: data, maxBoards: data[0].maxboards })
            console.log(data);
       })
      .catch(err => console.error(url, err.toString()))

    }
    loadBoards() {

        //load boards
        let url = process.env.REACT_APP_API_URI + '/api/org/v2/' + this.state.orgId;

        fetch(url, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
           this.setState({boards: data })
        })
       .catch(err => console.error(url, err.toString()))

    }
    removeFromOrg(board) {

        var r = window.confirm("Are you sure that you would like to unlink this board from the org?\n\nIt will still be available to the patient but will no longer count towards the limit of boards in your org and will be removed from this dashboard.");
        if (r === true) {
            let url = process.env.REACT_APP_API_URI + '/api/org/removeboard/' + board.id;
            let newBoards = this.state.boards;
    
            fetch(url, {method: 'GET'})
            .then(response => response.json())
            .then(data => {
                newBoards = newBoards.filter(function( obj ) {
                    return obj.id !== board.id;
                });
                this.setState({boards: newBoards});
                cogoToast.success('Board removed.');
            })
           .catch(err => console.error(url, err.toString()))
        
        }

    }
    resendWelcome(board) {

        var r = window.confirm("Are you sure that you would like to resend the welcome email?");
        if (r === true) {

            let url = process.env.REACT_APP_API_URI + '/api/board/welcome/' + board.id;

            fetch(url, {method: 'GET'})
            .then(response => response.json())
            .then(data => {
                console.log(data);
                cogoToast.success('Welcome email resent.');
            })
        .catch(err => console.error(url, err.toString()))
        }

    }
    handleOpenModal (id) {
        this.setState({ showModal: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
        this.loadBoards();
    }
    componentDidMount() {
        this._initializeComponent();
    }
    formatAxisDate(tickItem) {
        return moment(tickItem).format('MMMM Do') 
    }
    render(){
        return(
            <div>

                <ReactModal 
                isOpen={this.state.showModal}
                onRequestClose={this.handleCloseModal}
                className="Modal"
                overlayClassName="Overlay"
                >
                <CreateBoard orgId={this.state.orgId} closeTheModal={this.handleCloseModal}/>
                </ReactModal>

                <div className="dashboard pt-4 border-t-4 border-blue font-sans">
                <Nav title={this.state.orgcode} />
                

                    {this.state.isFetching &&
                        <div className="flex justify-center items-center h-screen font-bold">
                            <div className="text-center pb-4 py-2 m-2">
                            <Loader />
                            </div>
                        </div>
                    }
                    
                    {!this.state.isFetching &&
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 pl-4 pr-4">
                                <div className="relative mb-3 p-4 border w-full rounded overflow-hidden shadow-md" style={{ minHeight: 320}}>

                                    <h2 ref="title" className="mb-3 pb-2 text-lg text-black">
                                        Boards in Org
                                    </h2>
                                    
                                    
                                    <div className="leading-tight text-black text-base bg-white">
                                    <div className="table-wrapper" style={{ height: 180}}>
                                        <table className="text-left w-full border-collapse" style={{ marginRight: 20}}>
                                        <thead>
                                            <tr>
                                            <th className="py-2 px-2 bg-grey-lightest font-bold uppercase text-sm text-grey-dark">Name</th>
                                            <th className="py-2 px-2 bg-grey-lightest font-bold uppercase text-sm text-grey-dark">Last Updated</th>
                                            <th className="py-2 px-2 bg-grey-lightest font-bold uppercase text-sm text-grey-dark w-28">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                        { this.state.boards.map((board,i) => {
                                        const updatedAgo = moment(board.lastupdated).fromNow();
                                            return <tr className="hover:bg-grey-lighter" key={i}>
                                                <td className="py-2 px-2 border-b border-grey-light">{board.name}</td>
                                                <td className="py-2 px-2 border-b border-grey-light">{updatedAgo}</td>
                                                <td className="py-2 px-2 border-b border-grey-light"> 
                                                    <div className="flex justify-around">
                                                        <Link to={`/boardr/${board.id}`} className="text-black" aria-label="View Read Only"  data-balloon-pos="left" className="mr-4"><Icon icon='eye' width='16' show='true' /></Link>

                                                        <a style={{cursor: 'pointer'}} className="mr-4" onClick={this.resendWelcome.bind(null, board)} aria-label="Resend Welcome Email" data-balloon-pos="left"><Icon icon='envelope' width='16' show='true' /></a>

                                                        <a style={{cursor: 'pointer'}} onClick={this.removeFromOrg.bind(null, board)} aria-label="Remove from Org" data-balloon-pos="left"><Icon icon='delete' width='16' show='true' /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            })
                                        }

                                        </tbody>
                                        </table>
                                    </div>

                                    <div className="text-sm px-2 pt-2 w-full">You are currently using {this.state.boards.length} of {this.state.maxBoards} boards available under your current license.</div>

                                    {this.state.boards.length < this.state.maxBoards &&
                                        <div className="w-full clearfix"><button onClick={() => this.handleOpenModal(this.state.orgcode)} className="bg-blue hover:bg-blue-dark text-white text-sm py-2 px-4 mt-4 rounded-full float-left">Invite New Patient</button></div>
                                    }
                                    </div>
                                </div> 
                            </div>
                            <div className="w-full pr-4 pl-4 md:w-1/2 md:pl-0">
                                <div className="relative mb-3 p-4 border w-full rounded overflow-hidden shadow-md" style={{ height: 320}}>

                                    <h2 ref="title" className="mb-3 pb-2 text-lg text-black">
                                    Real Time Content Analysis
                                    </h2>

                                    <div className="leading-tight text-black text-base bg-white">
                                        <div style={{ width: '100%', height: 300}}>
                                        <ResponsiveContainer>
                                            <ScatterChart
                                                margin={{
                                                top: 20, right: 30, left: 20, bottom: 40,
                                                }}
                                            >
                                                
                                                <XAxis type="number" dataKey="sentimentscore" name="Positive" label={{ value: '< Negative | Positive >', position: 'top'}} tick={false} />
                                                <YAxis type="number" dataKey="sentimentmagnitude" name="Intensity" label={{ value: 'Intensity', angle: -90 }}tick={false} />
                                                <ZAxis type="number" dataKey="sentimentmagnitude" range={[100, 1000]} />
                                                <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }}  />
                                                <Scatter name="Sentiment by Boards in Your Org" data={this.state.boards}>
                                                {
                                                    this.state.boards.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                                                }
                                                </Scatter>

                                            </ScatterChart>
                                        </ResponsiveContainer>
                                        </div>
                                    
                                    </div>
                                </div>

                            </div>

                            <div className="w-full pl-4 pr-4">
                                <div className="relative mb-3 p-4 border w-full rounded overflow-hidden shadow-md">

                                    <h2 ref="title" className="mb-3 pb-2 text-lg text-black">
                                    Content Tone Trend by Day
                                    </h2>

                                    <div className="leading-tight text-black text-base bg-white">
                                        
                                        <div style={{ width: '100%', height: 300 }}>
                                        <ResponsiveContainer>
                                            <LineChart
                                                data={this.state.stats[0]}
                                                margin={{
                                                top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="statdate"  tickFormatter={this.formatAxisDate} />
                                                <YAxis type="number" domain={[-1, 1]}/>
                                                
                                                <Line type="monotone" dataKey="statvalue" name="Your Organization" stroke="#3490dc" activeDot={{ r: 8 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>


                            <div className="w-full md:w-1/2 pl-4 pr-4">
                                <div className="relative mb-3 p-4 border w-full rounded overflow-hidden shadow-md">

                                    <h2 ref="title" className="mb-3 pb-2 text-lg text-black">
                                    Common Needs in Org
                                    </h2>
                                    
                                    <div className="leading-tight text-black text-base bg-white">
                                    
                                    <div>
                                        <ReactWordcloud
                                        options={{
                                            colors: ['#3490dc'],
                                            rotations: 0,
                                            fontFamily: 'sans-serif',
                                            fontSizes: [12, 30],
                                        }}
                                        words={this.state.needs} />
                                    </div>
                                        
                                    </div>
                                </div> 


                            </div>
                            <div className="w-full pr-4 pl-4 md:w-1/2 md:pl-0">
                                <div className="relative mb-3 p-4 border w-full rounded overflow-hidden shadow-md">

                                    <h2 ref="title" className="mb-3 pb-2 text-lg text-black">
                                    Common Goals in Org
                                    </h2>

                                    <div className="leading-tight text-black text-base bg-white">

                                    <div>
                                        <ReactWordcloud 
                                        options={{
                                            colors: ['#3490dc'],
                                            rotations: 0,
                                            fontFamily: 'sans-serif',
                                        }}
                                        words={this.state.goals} />
                                    </div>
                                    
                                    </div>
                                </div>

                            </div>
                        </div>
                       
                    }
                </div>
            <Footer />
            </div>
        )
    }
} 

export default Vieworg;