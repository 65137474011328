import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationZH from "./translations/zh.json";
import translationFR from "./translations/fr.json";
import translationES from "./translations/es.json";
import translationDE from "./translations/de.json";
import translationAR from "./translations/ar.json";

// the translations
const resources = {
  zh: {
    translation: translationZH
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  },
  de: {
    translation: translationDE
  },
  ar: {
    translation: translationAR
  }
};
i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false
    },

    // react i18next special options (optional)
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
  });

export default i18n;